import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/home.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () =>
      import(/* webpackChunkName: "service" */ "@/views/service/service.vue"),
  },
  {
    path: "/appraisal",
    name: "appraisal",
    component: () =>
      import(
        /* webpackChunkName: "appraisal" */ "@/views/appraisal/appraisal.vue"
      ),
  },
  {
    path: "/findUniversity",
    name: "findUniversity",
    meta: { keepAlive: true },
    component: () =>
      import(
        /* webpackChunkName: "findUniversity" */ "@/views/findUniversity/findUniversity.vue"
      ),
  },
  {
    path: "/findMajor",
    name: "findMajor",
    component: () =>
      import(
        /* webpackChunkName: "findMajor" */ "@/views/findMajor/findMajor.vue"
      ),
  },
  {
    path: "/findRule",
    name: "findRule",
    component: () =>
      import(
        /* webpackChunkName: "findRule" */ "@/views/findRule/findRule.vue"
      ),
  },
  {
    path: "/outline",
    name: "outline",
    component: () =>
      import(/* webpackChunkName: "outline" */ "@/views/outline/outline.vue"),
  },
  {
    path: "/findEnroll",
    name: "findEnroll",
    component: () =>
      import(
        /* webpackChunkName: "findEnroll" */ "@/views/findEnroll/findEnroll.vue"
      ),
  },
  {
    path: "/findPayFees",
    name: "findPayFees",
    component: () =>
      import(
        /* webpackChunkName: "findPayFees" */ "@/views/findPayFees/findPayFees.vue"
      ),
  },

  {
    path: "/result",
    name: "result",
    component: () =>
      import(/* webpackChunkName: "result" */ "@/views/result/result.vue"),
  },
  {
    path: "/particulars",
    name: "particulars",
    component: () =>
      import(
        /* webpackChunkName: "particulars" */ "@/views/particulars/particulars.vue"
      ),
  },
  {
    path: "/majorParticulars",
    name: "majorParticulars",
    component: () =>
      import(
        /* webpackChunkName: "majorParticulars" */ "@/views/majorParticulars/majorParticulars.vue"
      ),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
